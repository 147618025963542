import { useState, useEffect } from "react"
import "./empty_state.scss"
import breakpoints from "foundation/_responsive_export.scss?variables"

interface Props {
  heading: string
  copy: string
  cta?: string
  ctaUrl?: string
  illustration?: string
  extraClass: string
}

const EmptyStateCta = ({ heading, copy, cta, ctaUrl, illustration = "mag", extraClass }: Props) => {
  const widthMatcher = window.matchMedia?.(`(max-width: ${breakpoints.screenXsMax})`)
  const [isMobile, setMobile] = useState(widthMatcher?.matches)
  const additionalClass = extraClass ? extraClass : ""

  useEffect(() => {
    const widthListener = (event: { matches: any }) => setMobile(event.matches)
    widthMatcher?.addEventListener("change", widthListener)

    return () => {
      widthMatcher?.removeEventListener("change", widthListener)
    }
  }, [])

  return (
    <div styleName={`empty-state-container ${additionalClass}`}>
      <div styleName={`illustration`} className={`${illustration === "mag" ? "empty-state-mag" : "empty-state-box"}`} />
      <h4>{heading}</h4>
      <p className="txt-body-3">{copy}</p>
      {cta && ctaUrl && (
        <a href={ctaUrl} className={isMobile ? "button-tertiary" : "button-secondary"}>
          {cta}
        </a>
      )}
    </div>
  )
}

export default EmptyStateCta
