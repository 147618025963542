import { Component } from "react"
import PropTypes from "prop-types"

import loadHeroImage from "shared/lib/load_hero_image"
import { partialStoryShape } from "app/lib/shared_prop_types"
import classNames from "classnames"

class StoryCard extends Component {
  render() {
    const heroImage = loadHeroImage(this.props.story.hero_image, "rectangular")
    const overlayColor = this.props.story.hero_image?.background_color

    return (
      <div className="explore-tile">
        <a
          className="story-box"
          title={this.props.story.title}
          aria-label={this.props.story.title}
          ref={el => {
            this.scrollObserverEl = el
          }}
          onClick={this.props.onClick}
          href={`/${this.props.singleStoryBasePath}/${this.props.story.slug}`}
        >
          <div className={classNames("cta", { shadow: !overlayColor })}>
            <div className="story-cta">
              <div className="category">
                <h5>{this.props.story.category}</h5>
              </div>
              <div className="title">
                <h4>{this.props.story.title}</h4>
              </div>
            </div>
          </div>
          <div
            className="overlay"
            style={{
              background: `linear-gradient(180deg, ${overlayColor}40 0%, ${overlayColor} 70%, ${overlayColor} 100%)`,
            }}
          ></div>
          <div
            className={classNames("image", { "full-height": !overlayColor })}
            style={{ backgroundImage: `url(${heroImage})` }}
          ></div>
        </a>
      </div>
    )
  }
}

StoryCard.propTypes = {
  onClick: PropTypes.func,
  singleStoryBasePath: PropTypes.string.isRequired,
  story: partialStoryShape.isRequired,
}

export default StoryCard
