import { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import throttle from "lodash/throttle"

import { loadMoreApplets } from "../reducers/search_results"

import Spinner from "shared/components/spinner"
import spinnerConfig from "../utils/spinner_config"
import SearchAppletCard from "./applet_card"
import { connectionShape } from "app/lib/shared_prop_types"

export class AppletsView extends Component {
  loadMoreResults = throttle(() => {
    if (
      this.props.applets.length > 0 &&
      this.props.applets.length < 100 &&
      !this.props.appletsLoading &&
      this.props.shouldLoadMoreApplets &&
      !this.props.resultsAreStale
    ) {
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight * 0.65) {
        this.props.loadMoreApplets(this.props.appletOffset, this.props.searchTerm)
      }
    }
  }, 250)

  componentDidMount() {
    // Hack: Force resize to update flex
    window.addEventListener("scroll", this.loadMoreResults, { passive: true })
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.loadMoreResults, {
      passive: true,
    })
  }

  render() {
    return (
      <div className="applets-container">
        <ul className="web-applet-cards get-more-search">
          {this.props.applets.map((applet, idx) => {
            return <SearchAppletCard key={idx} position={idx} applet={applet} term={this.props.searchTerm} />
          })}
        </ul>
        {this.props.appletsLoading && <Spinner config={spinnerConfig} />}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    applets: state.searchResults.applets,
    appletsLoading: state.searchResults.appletsLoading,
    appletOffset: state.searchResults.appletOffset,
    resultsAreStale: state.searchResults.resultsAreStale,
    searchTerm: state.searchResults.searchTerm,
    shouldLoadMoreApplets: state.searchResults.shouldLoadMoreApplets,
    origin: state.searchResults.origin,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    loadMoreApplets: (offset, term) => dispatch(loadMoreApplets(offset, term)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AppletsView)

AppletsView.propTypes = {
  applets: PropTypes.arrayOf(connectionShape),
  appletsLoading: PropTypes.bool,
  appletOffset: PropTypes.number,
  searchTerm: PropTypes.string,
  loadMoreApplets: PropTypes.func,
  resultsAreStale: PropTypes.bool,
  shouldLoadMoreApplets: PropTypes.bool,
  origin: PropTypes.string,
}

AppletsView.defaultProps = {
  applets: [],
  appletsLoading: false,
  origin: "",
}
