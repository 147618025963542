import { useState } from "react"
import { capitalize } from "humanize-plus"
import PropTypes from "prop-types"

import Modal from "shared/components/modal"
import "./suggestion_modal.scss"
import { sendPlatformSuggestion } from "shared/lib/graphql"

const SuggestionModal = ({
  channelId,
  feedbackTqaType,
  onClose,
  serviceName,
}) => {
  const [name, setName] = useState("")
  const [text, setText] = useState("")
  const [loading, setLoading] = useState(false)

  const onSubmit = async () => {
    setLoading(true)
    try {
      await sendPlatformSuggestion(
        Number(channelId),
        feedbackTqaType,
        name,
        text
      )
      window.appendFlash("Your suggestion has been submitted.")
    } catch {
      window.appendFlash(
        "Something went wrong while submitting your suggestion. Please try again later."
      )
    }
    setLoading(false)
    onClose()
  }

  return (
    <span styleName="modal">
      <Modal
        headerText={`Suggest a new ${feedbackTqaType} for ${serviceName}`}
        headerClass="modal-header"
        closeListener={onClose}
        show
        moveCloseXToHeader
        rightMargin
      >
        <div styleName="label">
          What new {feedbackTqaType} would you like to suggest?
        </div>
        <input
          styleName="modal-input"
          type="text"
          placeholder={`${capitalize(feedbackTqaType)} name`}
          value={name}
          onChange={e => setName(e.target.value)}
        />

        <div styleName="label">How would you use this {feedbackTqaType}?</div>
        <textarea
          styleName="modal-input"
          placeholder={`Briefly describe this ${feedbackTqaType}`}
          value={text}
          onChange={e => setText(e.target.value)}
        />

        <div styleName="cta">
          <button
            disabled={loading || !name || !text}
            onClick={onSubmit}
            className="button-tertiary"
          >
            {loading ? "Submitting..." : "Continue"}
          </button>
        </div>
      </Modal>
    </span>
  )
}

SuggestionModal.propTypes = {
  channelId: PropTypes.string.isRequired,
  feedbackTqaType: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  serviceName: PropTypes.string.isRequired,
}

export default SuggestionModal
