import { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import AppletsView from "./applets_view.js"
import RelatedServices from "../../related_services"
import EmptyStateCta from "shared/components/empty_state/index.tsx"
import { serviceShape, connectionShape } from "app/lib/shared_prop_types"

import Spinner from "shared/components/spinner"
import spinnerConfig from "../utils/spinner_config"

class SearchView extends Component {
  render() {
    return (
      <div className="search-view-container">
        {this.props.hideSuggestions ? (
          <>
            <main>
              {this.props.searchLoading ? (
                <Spinner config={spinnerConfig} />
              ) : (
                <>
                  <RelatedServices
                    services={this.props.services}
                    searchTerm={this.props.searchTerm}
                    path={service => `/${service.module_name}`}
                    location="search"
                  />
                  <AppletsView />
                </>
              )}
            </main>
          </>
        ) : (
          <EmptyStateCta
            heading="No results found"
            copy="We couldn't find an Applet or service that matched your query. Please try again or create your own Applet."
            cta="Create an Applet"
            ctaUrl={this.props.diyPath}
          />
        )}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    applets: state.searchResults.applets,
    services: state.searchResults.services,
    searchTerm: state.searchResults.searchTerm,
    searchLoading: state.searchResults.searchLoading,
    hideSuggestions: state.searchResults.hideSuggestions,
    diyPath: state.getMoreResults.diyPath,
  }
}

export default connect(mapStateToProps, null)(SearchView)

SearchView.propTypes = {
  applets: PropTypes.arrayOf(connectionShape),
  services: PropTypes.arrayOf(serviceShape),
  searchTerm: PropTypes.string,
  searchLoading: PropTypes.bool,
  hideSuggestions: PropTypes.bool,
  diyPath: PropTypes.string,
}

SearchView.defaultProps = {
  applets: [],
  searchLoading: false,
  hideSuggestions: true,
}
