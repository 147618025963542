import PropTypes from "prop-types"

import StoryCard from "shared/components/get_more/story_card"
import GetMoreServiceCard from "./service_card"
import GetMoreAppletCard from "./applet_card"
import { connectionShape, partialStoryShape, serviceShape } from "app/lib/shared_prop_types"
import { attachNewBadgeForButtons } from "shared/scripts/new_badge_button.tsx"
import { onClickSearchResult } from "../get_more_landing_page"

export const GetMoreSection = ({
  currentView,
  diyPath,
  isMobile,
  oddIndex,
  rotateCtaText,
  section: { applets, services, stories, appletsOffset, servicesOffset, storiesOffset },
}) => {
  const appletsToDisplay = applets.slice(0, applets.length - (applets.length % 2))
  const servicesToDisplay = services.slice(0, services.length - (services.length % 2))
  const shouldShowDiyCreateButton =
    ["discover_all", "discover_applets"].includes(currentView) && appletsToDisplay.length > 4 && oddIndex

  if (shouldShowDiyCreateButton) {
    attachNewBadgeForButtons()
  }
  // We need to get the position of elements on the screen, so each content type has an offset.
  return (
    <>
      {appletsToDisplay.length > 0 && (
        <>
          {["discover_all"].includes(currentView) && stories.length > 0 && (
            <StoryCard
              story={stories[0]}
              position={storiesOffset}
              singleStoryBasePath="explore"
              locationType="discover"
              onClick={() =>
                onClickSearchResult("explore_click_get_more_story", {
                  searchResultType: "story",
                  searchResultId: stories[0].slug,
                })
              }
            />
          )}
          {["discover_all", "discover_applets"].includes(currentView) && (
            <>
              {appletsToDisplay.slice(0, 4).map((applet, idx) => {
                return <GetMoreAppletCard applet={applet} key={idx} position={appletsOffset + idx} />
              })}
            </>
          )}
          {["discover_all", "discover_services"].includes(currentView) && (
            <>
              {servicesToDisplay.map((service, idx) => {
                return <GetMoreServiceCard service={service} key={idx} position={servicesOffset + idx} />
              })}
            </>
          )}
          {["discover_all"].includes(currentView) && stories.length > 1 && (
            <StoryCard
              story={stories[1]}
              position={storiesOffset + 1}
              singleStoryBasePath="explore"
              onClick={() =>
                onClickSearchResult("explore_click_get_more_story", {
                  searchResultType: "story",
                  searchResultId: stories[1].slug,
                })
              }
            />
          )}
          {["discover_all", "discover_applets"].includes(currentView) && (
            <>
              {appletsToDisplay.slice(4, 8).map((applet, idx) => {
                return <GetMoreAppletCard applet={applet} key={idx} position={appletsOffset + idx + 2} />
              })}
            </>
          )}
          {shouldShowDiyCreateButton && (
            <div className="explore-tile">
              <a className="cta-your-own-applet-container" href={diyPath}>
                <div className="get-more cta-your-own-applet" data-allow-new-badge="header_create_cta" href={diyPath}>
                  <h3 className="cta-text">
                    {rotateCtaText ? "Create your own Applet from scratch" : "Haven't found the right Applet?"}
                  </h3>
                  <button className={isMobile ? "button-secondary" : "button-tertiary"}>
                    {rotateCtaText ? "Get started" : "Create your own"}
                  </button>
                </div>
              </a>
            </div>
          )}
        </>
      )}
      {["discover_stories"].includes(currentView) && stories.length > 0 && (
        <>
          {stories.map((story, index) => (
            <StoryCard
              story={story}
              singleStoryBasePath="explore"
              key={index}
              onClick={() =>
                onClickSearchResult("explore_click_discover_story", {
                  searchResultType: "story",
                  searchResultId: story.slug,
                })
              }
            />
          ))}
        </>
      )}
    </>
  )
}

GetMoreSection.propTypes = {
  currentView: PropTypes.string,
  section: PropTypes.shape({
    applets: PropTypes.arrayOf(connectionShape).isRequired,
    services: PropTypes.arrayOf(serviceShape).isRequired,
    stories: PropTypes.arrayOf(partialStoryShape).isRequired,
    servicesOffset: PropTypes.number,
    appletsOffset: PropTypes.number,
    storiesOffset: PropTypes.number,
  }).isRequired,
  onAppletClick: PropTypes.func,
  diyPath: PropTypes.string,
  oddIndex: PropTypes.bool,
  isMobile: PropTypes.bool,
  rotateCtaText: PropTypes.bool,
}

GetMoreSection.defaultProps = {
  section: {
    applets: [],
    stories: [],
    services: [],
  },
}
