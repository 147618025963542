import { Component } from "react"
import PropTypes from "prop-types"
import SmallAppletCard from "shared/components/applet_card/ifttt_next/small_applet_card"
import { connectionShape } from "app/lib/shared_prop_types"
import appletOrConnectionPath from "app/scripts/applet_or_connection_path"
import { Applet } from "types"

interface Props {
  applet: Applet,
  position: number
}

export default class MyServiceAppletCard extends Component<Props> {
  propTypes = {
    applet: connectionShape.isRequired,
    position: PropTypes.number.isRequired,
  }

  render() {
    return (
      <li className="my-web-applet-card web-applet-card">
        <SmallAppletCard appletPath={appletOrConnectionPath(this.props.applet)} applet={this.props.applet} />
      </li>
    )
  }
}

MyServiceAppletCard.propTypes = {
  applet: connectionShape.isRequired,
  position: PropTypes.number.isRequired,
}
