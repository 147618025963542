import { Component } from "react"
import PropTypes from "prop-types"
import parse from "html-react-parser"

import exitIcon from "images/icons/icon-x.svg"

export default class SearchInput extends Component {
  // Public: focus the <textarea>.
  focus() {
    this.textinputRef?.focus()
  }

  render() {
    return (
      <>
        <input
          id={this.props.inputID}
          className="ifttt-next-search-input"
          type="text"
          placeholder={this.props.placeholder}
          autoFocus={this.props.autoFocus}
          onChange={this.props.onChange}
          value={this.props.value}
          onClick={this.props.onClick}
          onBlur={this.props.onBlur}
          maxLength={this.props.maxLength}
          autoComplete={this.props.autoComplete}
          ref={r => (this.textinputRef = r)}
          spellCheck={false}
          aria-label={this.props.inputID}
        />
        {this.props.showCloseIcon && this.props.value && (
          <span className="svg-icon x-click" onClick={this.props.onXClick}>
            {parse(exitIcon)}
          </span>
        )}
      </>
    )
  }
}

SearchInput.propTypes = {
  autoComplete: PropTypes.string,
  autoFocus: PropTypes.bool,
  inputID: PropTypes.string,
  maxLength: PropTypes.string,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  onBlur: PropTypes.func,
  onXClick: PropTypes.func,
  placeholder: PropTypes.string,
  showCloseIcon: PropTypes.bool,
  value: PropTypes.string,
}

SearchInput.defaultProps = {
  autoComplete: "off",
  autoFocus: true,
  inputID: "search",
  maxLength: "1024",
  placeholder: "Search",
  showCloseIcon: true,
  value: "",
}
