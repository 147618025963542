import { gql, graphQueryBang } from "shared/lib/graphql"
import { normalizedAppletFields } from "shared/lib/gql_fragments"

const worksWellQuery = gql`
  query worksWellQuery($module_name: String!) {
    channel(module_name: $module_name) {
      works_well_with {
        module_name
        name
        icon_bw: lrg_monochrome_image_url
        brand_color
      }
      discover(
        story_audience: user
        platform: web
        page: all
      ) {
        applets_for_channel {
          ${normalizedAppletFields}
        }
      }
    }
  }
`

const appletsFromTwoChannelsQuery = gql`
  query(
    $module_names: [String]
    $first_channel: String!
    $second_channel: String!
    $offset: Int
    $limit: Int
  ) {
    channels(module_names: $module_names) {
      id
    }
    applets_from_two_channels(
      first_channel_module_name: $first_channel
      second_channel_module_name: $second_channel
      platform: web
      limit: $limit
      offset: $offset
    ) {
      id
    }
  }
`

export const getServiceAndApplets = moduleName => {
  return graphQueryBang(worksWellQuery, { module_name: moduleName })
}

export const getAppletsFromTwoChannels = (
  firstModuleName,
  secondModuleName
) => {
  return graphQueryBang(appletsFromTwoChannelsQuery, {
    module_names: [firstModuleName, secondModuleName],
    first_channel: firstModuleName,
    second_channel: secondModuleName,
    limit: 2,
    offset: 0,
  })
}
