import { Component } from "react"

export default function upsellPlugHOC(WrappedComponent) {
  return class platformPlug extends Component {
    constructor() {
      super()
      this.upsellPlugInjected = false
    }

    proc(wrappedComponentInstance) {
      this.instanceRef = wrappedComponentInstance
    }

    componentDidUpdate() {
      // React doesn't care about outside DOM manipulation as long as it's done
      // between render cycles
      this.injectUpsellPlugStory()
    }

    injectUpsellPlugStory = () => {
      if (!this.upsellPlugInjected) {
        const originalStory = document.querySelector(".upsell-plug-story")
        const beforeEl = document.querySelectorAll(".service-cards")[1]

        if (beforeEl && originalStory) {
          const story = originalStory.cloneNode(true)
          story.style.display = "block"

          beforeEl.parentNode.insertBefore(story, beforeEl.nextSibling)
          this.upsellPlugInjected = true
        }
      }
    }

    render() {
      const props = {
        ...this.props,
        ref: this.proc.bind(this),
      }
      return <WrappedComponent {...props} />
    }
  }
}
