import PropTypes from "prop-types"
import MyServiceAppletCard from "./applet_card"
import { Applet } from "types"

interface Props {
  applets: Applet[]
}

export const MyServiceView = (props: Props) => {
  return (
    <ul className="web-applet-cards">
      {props.applets.map((applet, idx) => {
        return <MyServiceAppletCard key={idx} applet={applet} position={idx} />
      })}
    </ul>
  )
}

MyServiceView.propTypes = {
  applets: PropTypes.array,
}

MyServiceView.defaultProps = {
  applets: [],
}
