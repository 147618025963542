const spinnerConfig = {
  left: "50%",
  bottom: "0%",
  length: 6,
  width: 6,
  radius: 20,
  corners: 0,
  scale: 0.55,
  position: "relative",
}

export default spinnerConfig
