import { Component } from "react"
import PropTypes from "prop-types"

import ServiceCard from "./service_card"
import { serviceShape } from "app/lib/shared_prop_types"

export class RelatedServices extends Component {
  render() {
    return (
      <div className="search-view-container">
        <div className="services-container">
          <ul className="service-cards">
            {this.props.services.map((service, idx) => {
              return (
                <ServiceCard
                  service={service}
                  key={idx}
                  position={idx}
                  term={this.props.searchTerm}
                  path={this.props.path(service)}
                  location={this.props.location}
                />
              )
            })}
          </ul>
        </div>
      </div>
    )
  }
}
export default RelatedServices

RelatedServices.propTypes = {
  services: PropTypes.arrayOf(serviceShape),
  searchTerm: PropTypes.string,
  path: PropTypes.func.isRequired,
  location: PropTypes.string.isRequired,
}

RelatedServices.defaultProps = {
  services: [],
}
