export default function loadHeroImage(images, cardType) {
  if (images.background_image_url_1x) {
    return storyCardImage(images, cardType)
  } else if (images.solutionImage) {
    return solutionCardImage(images, cardType)
  }
}

const storyCardImage = (images, cardType) => {
  switch (cardType) {
    case "rectangular":
      return images.background_image_url_1x.replace("w=720&h=360", "w=900&h=450")
    case "square":
      return images.background_image_url_1x
    default:
      if (window.innerWidth >= "1444px") {
        return images.background_image_url_3x
      } else {
        return images.background_image_url_2x
      }
  }
}

const solutionCardImage = (images, cardType) => {
  switch (cardType) {
    case "rectangular":
      return `${images.solutionImage}?w=900&h=450`
    case "square":
      return `${images.solutionImage}?w=720&h=360`
    default:
      if (window.innerWidth >= "1444px") {
        return `${images.solutionImage}?w=2880&h=1440`
      } else {
        return `${images.solutionImage}?w=1440&h=720`
      }
  }
}
