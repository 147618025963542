import { gql } from "./utils"

export const normalizedAppletFields = gql`
  id
  config_type
  name
  archived
  description
  icon_url
  monochrome_icon_url
  status
  service_slug
  brand_color
  installs_count
  author
  author_image_url
  author_tier
  intermediate_pro_features
  pro_features
  run_count
  just_enabled
  last_run
  created_at
  author_id
  by_service_owner
  instant
  speed
  push_enabled
  can_push_enable
  published
  background_images {
    sm_background_image_url
    background_image_url_1x
  }
  channels {
    name
    module_name
    image_url
    monochrome_image_url
    brand_color
    available_to_everyone
  }
`

export const normalizedAppletFragment = gql`
  normalized_applet {
    ${normalizedAppletFields}
  }
`
