import { Component } from "react"
import PropTypes from "prop-types"
import SmallAppletCard from "shared/components/applet_card/ifttt_next/small_applet_card"
import { connectionShape } from "app/lib/shared_prop_types"
import appletOrConnectionPath from "app/scripts/applet_or_connection_path"
import { onClickSearchResult } from "../get_more_landing_page"

export class SearchAppletCard extends Component {
  render() {
    return (
      <div className="explore-tile">
        <li
          className="my-web-applet-card web-applet-card"
          ref={el => {
            this.scrollObserverEl = el
          }}
        >
          <SmallAppletCard
            applet={this.props.applet}
            appletPath={appletOrConnectionPath(this.props.applet)}
            onAppletClick={() =>
              onClickSearchResult("explore_click_applet_search_result", {
                searchResultType: "applet",
                searchResultId: this.props.applet.id,
              })
            }
          />
        </li>
      </div>
    )
  }
}

SearchAppletCard.propTypes = {
  applet: connectionShape.isRequired,
  term: PropTypes.string,
  position: PropTypes.number.isRequired,
}

export default SearchAppletCard
